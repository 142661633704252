//@flow
import Raven from 'raven-js';
import { useEffect, useState } from 'react';
import { getUserAccount, REJECT_REASON } from '@dt/session';
import {
  clearSessionAndRedirectToExpired,
  redirectToLogin,
  getUserAccountCache,
} from '@dt/session';
import { useSelector, useDispatch } from 'react-redux';
import { sessionCreate } from './redux/user_sessions/actions';

type UseSessionOptions = {
  // Allows this hook to "complete" when the user account is available from `sevenhell`.
  // Without getting a session from `horizon`.
  //
  // TODO: Should assume that users that can not use the API product are still provided a session.
  //       (ie `requireUserSession` should *not* have to be used here.)
  //       When `horizon` supports this behavior this can be safely removed.
  //       However, make sure that pages are redirect appropriately based on access controls.
  +requireUserSession?: boolean,

  // Allows this hook to "complete" when the user account is unavialble from `sevenhell`.
  // Acting as a pass through for actors who have temporary access tokens.
  // Will also prevent the auto redirection that occurs.
  +requireUserAccount?: boolean,
};

/*
 * Retreives the current actor session.
 *
 * Will, potentially, request the actor session information.
 *
 * TODO@nw: This needs a lot more work to be the source of truth for actor session information.
 *          - Should support temporary session tokens
 * TODO@nw: Update to use an actor model.
 */
export const useSession = (options: ?UseSessionOptions) => {
  const requireUserSession =
    typeof options?.requireUserSession === 'boolean'
      ? options.requireUserAccount
      : true;
  const requireUserAccount =
    typeof options?.requireUserAccount === 'boolean'
      ? options.requireUserAccount
      : true;

  const user_sessions = useSelector(s => s.user_sessions);
  // TODO: This is crazy.
  //       Needs updating to store user session information entirely in this hook.
  //       Make sure to `useRef` / cache the user account & user session so its not
  //       triggering unnecassary rerenders.
  const [userAccount, setUserAccount] = useState(getUserAccountCache());

  const dispatch = useDispatch();

  useEffect(
    () => {
      let isMounted = true;

      if (user_sessions.success) {
        return;
      }

      // Obtain user_account from sevenhell.
      getUserAccount()
        .then(result => {
          if (!isMounted) {
            return;
          }

          if (!result.no_session_reason) {
            setUserAccount(result);

            // Obtain user_session from horizon.
            if (requireUserSession) {
              dispatch(sessionCreate(result.sessionId));
            }
          } else {
            if (requireUserAccount) {
              // Redirect when no user account was found.
              if (result.no_session_reason === REJECT_REASON.NO_SESSION_ID) {
                redirectToLogin();
              } else if (
                result.no_session_reason === REJECT_REASON.EXPIRED_SESSION_ID
              ) {
                clearSessionAndRedirectToExpired();
              } else {
                const error = new Error('Response Invalid');
                console.error(error);
                Raven.captureException(error, {
                  extra: {
                    msg: 'An error occurred fetching the user account.',
                  },
                });
                clearSessionAndRedirectToExpired();
              }
            }
          }
        })
        .catch(e => {
          Raven.captureException(e, {
            extra: { msg: 'An error occurred fetching the user account.' },
          });
          clearSessionAndRedirectToExpired();
        });

      return () => {
        isMounted = false;
      };
    },
    // NOTE: Pulling from the store will force a deps change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requireUserAccount, requireUserSession, dispatch],
  );

  return {
    user_account: requireUserSession
      ? user_sessions.success
        ? userAccount
        : null
      : userAccount,
    user_session: user_sessions.success ? user_sessions.current_session : null,
  };
};
