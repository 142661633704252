// @flow
import React, { type Node } from 'react';
import type { HostedOn, DiscoveredViaType } from '@dt/horizon-api';
import { Card, Typography, Grid } from '@material-ui/core';
import TagDiscoveredVia from './TagDiscoveredVia';
import TagHostedOn from './TagHostedOn';
import TagApiOperationsCount from './TagApiOperationsCount';
import PolicyViolationsTagStrip from './PolicyViolationsTagStrip';
import UnifiedAssetTypeIcon from './UnifiedAssetTypeIcon';
import { palette } from '@dt/theme';
import type {
  PolicyViolationStatusEnum,
  PolicyViolationsListQuery_policy_violations_list_policy_violations_violated_policy_rule,
} from '@dt/graphql-support/types/PolicyViolationsListQuery';

const styles = {
  assetCard: {
    padding: 16,
  },
  avatar: { background: palette.brand35 },
  violationTagStrip: {
    display: 'flex',
  },
  urlWrap: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
};

type Props = {|
  +name: string,
  +url: null | string,
  +hosted_on: HostedOn,
  +discovered_via: DiscoveredViaType,
  +date_created: string,
  +asset_type_icon_url: string,
  +asset_type_name: string,
  +policy_violations: ?$ReadOnlyArray<{
    status: PolicyViolationStatusEnum,
    violated_policy_rule: PolicyViolationsListQuery_policy_violations_list_policy_violations_violated_policy_rule,
    ...
  }>,
  +api_operations_count: ?number,

  children?: Node,
|};

const AssetCard = ({
  name,
  url,
  discovered_via,
  hosted_on,
  policy_violations,
  api_operations_count,
  date_created,
  asset_type_icon_url,
  asset_type_name,
  children,
}: Props) => (
  <Card style={styles.assetCard}>
    <Grid container alignItems="flex-start" wrap="nowrap" spacing={2}>
      <Grid item>
        <UnifiedAssetTypeIcon
          asset_type_icon_url={asset_type_icon_url}
          asset_type_name={asset_type_name}
          iconSize={36}
        />
      </Grid>
      <Grid
        item
        container
        justify="space-between"
        direction="column"
        spacing={1}
        style={{ maxWidth: 'calc(100% - 48px)' }} // Need this to make sure that content does not overflow the card
      >
        <Grid item container justify="space-between" wrap="nowrap">
          <Grid item>
            <Typography variant="body1" component="div" style={styles.urlWrap}>
              {name}
            </Typography>
            {url && (
              <Typography
                variant="caption"
                component="div"
                style={styles.urlWrap}
              >
                {url}
              </Typography>
            )}
          </Grid>
          {policy_violations && (
            <Grid item>
              <PolicyViolationsTagStrip
                policy_violations_data={policy_violations.map(
                  ({ status, violated_policy_rule }) => ({
                    status,
                    relevance: violated_policy_rule.relevance,
                  }),
                )}
              />
            </Grid>
          )}
        </Grid>
        <Grid item container justify="space-between" wrap="nowrap">
          <Grid item>
            <div style={{ display: 'flex' }}>
              <TagDiscoveredVia
                discovered_via={discovered_via}
                discovered_date={date_created}
              />
              <TagHostedOn hosted_on={hosted_on} />
            </div>
          </Grid>
          <Grid item style={{ alignSelf: 'flex-end' }}>
            <TagApiOperationsCount count={api_operations_count || 0} />
          </Grid>
        </Grid>

        {children && (
          <Grid item container justify="space-between" wrap="nowrap">
            <Grid item style={{ width: '100%' }}>
              {children}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  </Card>
);

export default React.memo<Props>(AssetCard);
