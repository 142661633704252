// @flow
import React, { PureComponent } from 'react';
import UrgentCriticalIcon from '@material-ui/icons/Warning';
import ImportantWarningIcon from '@material-ui/icons/Flag';
import InformationalProactiveIcon from '@material-ui/icons/Info';
import ResolvedResolutionIcon from '@material-ui/icons/ThumbUp';
import Label from './Label';
import {
  palette,
  oldPaletteToNewPaletteMap,
  type OldPaletteToNewPaletteType,
} from '@dt/theme';

type Props = {
  type: OldPaletteToNewPaletteType,
  huge?: boolean,
  tiny?: boolean,
  color?: $Values<typeof palette>,
  background?: null | 'gray' | 'red' | 'blue' | 'green',
  ...
};

export default class PolicyViolationIcon extends PureComponent<Props> {
  render() {
    const { type, huge, tiny, background, color } = this.props;

    const size = huge ? 48 : tiny ? 12 : 18;

    const props = {
      style: {
        width: size,
        height: size,
        color: color ? color : oldPaletteToNewPaletteMap[type],
      },
    };

    if (type === 'bad') {
      return (
        <Label variant={background || 'red'}>
          <UrgentCriticalIcon {...props} />
        </Label>
      );
    } else if (type === 'warning') {
      return (
        <Label variant={background || 'yellow'}>
          <ImportantWarningIcon {...props} />
        </Label>
      );
    } else if (type === 'info') {
      return (
        <Label variant={background || 'blue'}>
          <InformationalProactiveIcon {...props} />
        </Label>
      );
    } else if (type === 'good') {
      return (
        <Label variant={background || 'green'}>
          <ResolvedResolutionIcon {...props} />
        </Label>
      );
    } else if (type === 'neutral') {
      return (
        <Label variant={background || 'gray'}>
          <ResolvedResolutionIcon {...props} />
        </Label>
      );
    } else {
      throw new Error('invalid type prop passed to PolicyViolationIcon');
    }
  }
}
