// @flow
import type { Saga } from 'redux-saga';
import { spawn, take, put, all } from 'redux-saga/effects';

import { watchForCreateSession } from '../user_sessions/sagas';
import {
  sessionCreateSuccess,
  sessionCreateError,
} from '../user_sessions/actions';
import { sagasCreated } from './actions';

export default function* containerFlow(): Saga<void> {
  yield spawn(watchForCreateSession);

  yield all([
    spawn(require('./../network_services/sagas').default),
    spawn(require('./../inventory/sagas').default),
    spawn(require('./../inventory/search/sagas').default),
    spawn(require('./../inventory/export/sagas').default),
    spawn(require('./../cloud_resources/sagas').default),
    spawn(require('./../configurations/sagas').default),
    spawn(require('./../dashboard/sagas').default),
    spawn(require('./../restful_apis/sagas').default),
    spawn(require('./../policy_violations/sagas').default),
    spawn(require('./../policy_violations/comments/sagas').default),
    spawn(require('./../policy_rules/sagas').default),
    spawn(require('./../events/sagas').default),
    spawn(require('./../web_applications/sagas').default),
    spawn(require('./../policies/sagas').default),
    spawn(require('./../alerts_integrations/sagas').default),
    spawn(require('./../asset_groups/sagas').default),
    spawn(require('./../hack_extract/sagas').default),
    spawn(require('./../shared_links/sagas').default),
    spawn(require('./../detect_inject/sagas').default),
  ]);

  yield put(sagasCreated());

  // TODO: We try to authenticate, but keep the train rolling if we fail.
  // This is because dev-horizon does not support authentication but
  // prod-horizon requires it. :/

  yield take(
    action =>
      action.type === sessionCreateSuccess.toString() ||
      action.type === sessionCreateError.toString(),
  );
}
