// @flow
import React from 'react';
import { palette } from '@dt/theme';
import PolicyViolationIcon from './PolicyViolationIcon';
import { Tooltip } from '@material-ui/core';

type Props = {|
  count: number,
  type: 'urgent' | 'important' | 'info',
  margins?: string,
|};

const PolicyViolationTag = ({ count, type, margins }: Props) => {
  const colors = {
    urgent: {
      backgroundColor: palette.red50,
      color: palette.red30,
    },
    important: {
      backgroundColor: palette.yellow50,
      color: palette.gray20,
    },
    info: {
      backgroundColor: palette.blue50,
      color: palette.blue30,
    },
  };

  let style = {
    backgroundColor: palette.gray45,
    color: palette.gray35,
  };

  if (Number(count) > 0) {
    style = colors[type];
  }

  const typeToHuman = type === 'info' ? 'proactive' : type;

  return (
    <Tooltip
      title={`${count} unresolved ${typeToHuman} policy violation${
        count === 0 || count > 1 ? 's' : ''
      }`}
      aria-label={`${typeToHuman} count`}
    >
      <div
        style={{
          ...style,
          padding: '2px 4px',
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          fontSize: 14,
          margin: margins ? margins : '0 0 0 8',
          whiteSpace: 'pre',
        }}
      >
        <PolicyViolationIcon
          color={style.color}
          background={Number(count) < 1 ? 'gray' : null}
          type={
            type === 'urgent'
              ? 'bad'
              : type === 'important'
              ? 'warning'
              : 'info'
          }
          tiny
        />
        <span style={{ marginLeft: 4 }}>{count}</span>
      </div>
    </Tooltip>
  );
};

export default React.memo<Props>(PolicyViolationTag);
