// @flow
import { createReducerCreator } from 'rezz';
import { type UserSession } from '@dt/horizon-api';
import { handleActions } from 'redux-actions';
import {
  sessionCreate,
  sessionCreateError,
  sessionCreateSuccess,
} from './actions';
import type { ActionType } from 'redux-actions';

export type State = {|
  current_session: ?UserSession,
  success: boolean,
  error: ?string,
|};

const initialState = {
  current_session: null,
  success: false,
  error: null,
};

export default createReducerCreator<State, _, _>({
  initialState,
})(
  handleActions(
    {
      [sessionCreate.toString()]: (state: State) => ({
        ...state,
        success: false,
        error: null,
      }),
      [sessionCreateSuccess.toString()]: (
        state: State,
        action: ActionType<typeof sessionCreateSuccess>,
      ) => ({
        ...state,
        current_session: action.payload,
        success: true,
        error: null,
      }),
      [sessionCreateError.toString()]: (
        state: State,
        action: ActionType<typeof sessionCreateError>,
      ) => ({
        ...state,
        success: false,
        error: action.payload,
      }),
    },
    initialState,
  ),
);
