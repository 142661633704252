//@flow
import React, { useState } from 'react';
import { Tab } from '@material-ui/core';
import { Tabs } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '@dt/theme';

import Markdown from './Markdown';

const withStyles = makeStyles(() => ({
  tabContainer: {
    minHeight: 160,
    overflowX: 'auto',
  },
  tabContainerContent: {
    padding: 14,
    borderTop: '1px solid ' + palette.gray40,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  tab: {
    minWidth: 72,
    maxWidth: 'inherit',
  },
  selectedTab: {
    color: palette.brand35,
    borderBottom: `2px solid ${palette.brand35}`,
  },
}));

type Props = {|
  api_operation_scan_trace: ?{
    http_request: string,
    http_response: string,
    ...
  },
  policy_violations: $ReadOnlyArray<{
    +id: string,
    +status: 'DELETED' | 'OPEN' | 'RESOLVED' | 'WONT_FIX',
    +additional_info: ?string,
    ...
  }>,
|};

function ApiOperationRequestResponse({
  api_operation_scan_trace,
  policy_violations,
}: Props) {
  const classes = withStyles();
  const [activeTab, setActiveTab] = useState('request');

  return (
    <Box
      borderRadius={0}
      style={{ backgroundColor: palette.gray50 }}
      px={3}
      py={1}
    >
      {/* Additional information: Only applicable to specific policy violations. */}
      {policy_violations.length > 0 && (
        <Typography variant="subtitle1" gutterBottom>
          Additional information
        </Typography>
      )}
      {policy_violations.map(
        ({ id, status, additional_info }) =>
          status === 'OPEN' && (
            <Typography key={id} variant="body2" component="div">
              <Markdown text={additional_info} />
            </Typography>
          ),
      )}

      {/* API Operation Request/Response Details */}
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        indicatorColor="primary"
        style={{ color: palette.brand35 }}
        aria-label="operation detail content"
      >
        <Tab
          value="request"
          label="Request"
          className={classes.tab}
          classes={{
            selected: classes.selectedTab,
          }}
        />
        <Tab
          value="response"
          label="Response"
          className={classes.tab}
          classes={{
            selected: classes.selectedTab,
          }}
        />
      </Tabs>

      <div className={classes.tabContainer}>
        <Typography
          component="pre"
          id="api-operation-panel"
          className={classes.tabContainerContent}
        >
          {activeTab === 'request'
            ? api_operation_scan_trace
              ? api_operation_scan_trace.http_request
              : 'Currently unavailable. Please check back later!'
            : null}
          {activeTab === 'response'
            ? api_operation_scan_trace
              ? api_operation_scan_trace.http_response
              : 'Currently unavailable. Please check back later!'
            : null}
        </Typography>
      </div>
    </Box>
  );
}

export default ApiOperationRequestResponse;
