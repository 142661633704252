// @flow
import { type Saga } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import type { ActionType } from 'redux-actions';

import {
  sessionCreate,
  sessionCreateSuccess,
  sessionCreateError,
} from '../user_sessions/actions';
import { user_sessions } from '@dt/horizon-api';

function* createSessionSaga(
  action: ActionType<typeof sessionCreate>,
): Saga<void> {
  try {
    if (!action.payload) {
      throw new Error('No session_token provided.');
    }

    const response = yield* callPromise(user_sessions.create, {
      session_token: action.payload,
    });

    if (response._type === 'error') {
      throw new Error(response.title);
    }

    yield put(sessionCreateSuccess(response.body));
  } catch (e) {
    yield put(sessionCreateError(e));
  }
}

export function* watchForCreateSession(): Saga<void> {
  yield takeEvery(sessionCreate.toString(), createSessionSaga);
}
