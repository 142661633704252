// @flow
import { createAction } from 'redux-actions';
import {
  type AlertsIntegrationsList,
  type AlertsIntegrationsServiceEnumType,
  type AlertsIntegrationsPatchParams,
} from '@dt/horizon-api';

export const alertsIntegrationsReceived = createAction<
  'alerts_integrations/received',
  [AlertsIntegrationsList],
  AlertsIntegrationsList,
>('alerts_integrations/received', alerts_integrations => alerts_integrations);

export const alertsIntegrationsMounted = createAction<
  'alerts_integrations/mounted',
  [],
  { key: 'alerts_integrations', ... },
>('alerts_integrations/mounted', () => ({ key: 'alerts_integrations' }));

export const alertsIntegrationEditCancel = createAction<
  'alerts_integrations/edit/cancel',
>('alerts_integrations/edit/cancel');

export const alertsIntegrationsCreate = createAction<
  'alerts_integrations/create',
  [
    {
      name: string,
      service_enum: AlertsIntegrationsServiceEnumType,
      webhook_url: string,
    },
  ],
  {
    name: string,
    service_enum: AlertsIntegrationsServiceEnumType,
    webhook_url: string,
  },
>('alerts_integrations/create', params => params);

export const alertsIntegrationsCreateSuccess = createAction<
  'alerts_integrations/create/success',
>('alerts_integrations/create/success');

export const alertsIntegrationsCreateError = createAction<
  'alerts_integrations/create/error',
  [string],
  string,
>('alerts_integrations/create/error', error => error);

export const alertsIntegrationsEdit = createAction<
  'alerts_integrations/edit',
  [string, AlertsIntegrationsPatchParams],
  [string, AlertsIntegrationsPatchParams],
>('alerts_integrations/edit', (id, params) => [id, params]);

export const alertsIntegrationsEditSuccess = createAction<
  'alerts_integrations/edit/success',
  [string],
  string,
>('alerts_integrations/edit/success', id => id);

export const alertsIntegrationsEditError = createAction<
  'alerts_integrations/edit/error',
  [string, string],
  {
    id: string,
    error: string,
    ...
  },
>('alerts_integrations/edit/error', (id, error) => ({ id, error }));

export const alertsIntegrationsDelete = createAction<
  'alerts_integrations/delete',
  [string],
  string,
>('alerts_integrations/delete', id => id);

export const alertsIntegrationsDeleteSuccess = createAction<
  'alerts_integrations/delete/success',
  [string],
  string,
>('alerts_integrations/delete/success', id => id);

export const alertsIntegrationsDeleteError = createAction<
  'alerts_integrations/delete/error',
  [string, string],
  {
    id: string,
    error: string,
    ...
  },
>('alerts_integrations/delete/error', (id, error) => ({ id, error }));
