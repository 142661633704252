// @flow
import React from 'react';
import { palette } from '@dt/theme';
import { Tooltip, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AssetTypeDict } from './types';
import { getDisplayNameForAsset } from './utils';

// DT Assets
import ApiIcon from '@material-ui/icons/DeviceHub';
import WebAppIcon from '@material-ui/icons/Web';
import APIDomainIcon from '@material-ui/icons/Language';

const useStyles = makeStyles({
  dtAssetAvatar: props => ({
    background: palette.gray20,
    height: props.iconSize ? props.iconSize : 36,
    width: props.iconSize ? props.iconSize : 36,
  }),
  avatar: props => ({
    background: palette.gray45,
    height: props.iconSize ? props.iconSize : 36,
    width: props.iconSize ? props.iconSize : 36,
    color: palette.gray35,
  }),
  materialIcon: props => ({
    color: palette.white,
    height: props.iconSize ? props.iconSize * 0.66 : 24,
    width: props.iconSize ? props.iconSize * 0.66 : 24,
  }),
});

type OwnProps = {|
  asset_type: $Values<typeof AssetTypeDict>,
  iconSize?: number,
|};

const AssetTypeIcon = props => {
  const { asset_type, iconSize } = props;
  const classes = useStyles(props);
  const getIconForAsset = (asset_type, iconSize, classes) => {
    if (asset_type === AssetTypeDict.SINGLE_PAGE_WEB_APP) {
      return (
        <Avatar
          className={classes.dtAssetAvatar}
          style={{ background: palette.brown }}
        >
          <WebAppIcon className={classes.materialIcon} />
        </Avatar>
      );
    }

    if (asset_type === AssetTypeDict.RESTFUL_API) {
      return (
        <Avatar
          className={classes.dtAssetAvatar}
          style={{ background: palette.orange }}
        >
          <ApiIcon className={classes.materialIcon} />
        </Avatar>
      );
    }

    if (asset_type === AssetTypeDict.API_DOMAIN) {
      return (
        <Avatar
          className={classes.dtAssetAvatar}
          style={{ background: palette.teal }}
        >
          <APIDomainIcon className={classes.materialIcon} />
        </Avatar>
      );
    }

    throw new Error(
      `Missing Icon for asset type ${asset_type}, AssetTypeDict is likely out of date`,
    );
  };

  return (
    <Tooltip title={getDisplayNameForAsset(asset_type)}>
      {getIconForAsset(asset_type, iconSize, classes)}
    </Tooltip>
  );
};

export default React.memo<OwnProps>(AssetTypeIcon);
