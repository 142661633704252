// @flow
import { type Saga } from 'redux-saga';
import { all, call } from 'redux-saga/effects';
import { takePatternAndFetch } from '../resource_fetch/sagas';
import {
  getAwsAuthenticators,
  getGcpAuthenticators,
  getAzureAuthenticators,
  getAxwayAuthenticators,
  getMulesoftAuthenticators,
  getApigeeAuthenticators,
  getKongAuthenticators,
} from './resource_fetch.sagas';
import { getOpenAPIDefinitions } from '../openapi_definitions/resource_fetch.sagas';
import { getAllIpRanges } from '../ip_ranges/sagas';
import { getAllAlertsIntegrations } from '../alerts_integrations/sagas';
import { getNetworkServicesList } from '../network_services/resource_fetch.sagas';
import { getAllAssetGroups } from '../asset_groups/resource_fetch.sagas';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    call(
      takePatternAndFetch,
      'mounted/configuration*',
      function*(): Saga<void> {
        yield all([
          call(getAwsAuthenticators, {}),
          call(getGcpAuthenticators, {}),
          call(getAzureAuthenticators, {}),
          call(getAxwayAuthenticators, {}),
          call(getMulesoftAuthenticators, {}),
          call(getApigeeAuthenticators, {}),
          call(getKongAuthenticators, {}),
        ]);
      },
    ),
    call(takePatternAndFetch, 'mounted/onboarding*', function*(): Saga<void> {
      yield all([
        call(getAwsAuthenticators, {}),
        call(getGcpAuthenticators, {}),
        call(getAzureAuthenticators, {}),
      ]);
    }),
    call(
      takePatternAndFetch,
      'mounted/security-roadmap/*',
      function*(): Saga<void> {
        yield all([
          call(getAllAlertsIntegrations, {}),
          call(getOpenAPIDefinitions, {}),
          call(getAllIpRanges, {}),
          call(getNetworkServicesList, {}),
          call(getAllAssetGroups, {}),
        ]);
      },
    ),
  ]);
}
