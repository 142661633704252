// @flow
import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';
import {
  type PolicyViolationStatusEnum,
  PolicyViolationStatusEnumValues,
} from '@dt/graphql-support/types';
import Label from './Label';

let useStyle = makeStyles({
  label: props => ({
    backgroundColor: props?.backgroundColor || palette.gray45,
    color: props?.color || palette.gray10,
    padding: '4px 8px',
    fontSize: '13px',
    fontWeight: 'bold',
  }),
});

type Props = {
  status: PolicyViolationStatusEnum,
};

const variants = {
  [PolicyViolationStatusEnumValues.WontFix]: {
    color: palette.gray10,
    backgroundColor: palette.gray45,
    label: "WON'T FIX",
  },
  [PolicyViolationStatusEnumValues.Open]: {
    color: palette.red10,
    backgroundColor: palette.red50,
    label: 'OPEN',
  },

  [PolicyViolationStatusEnumValues.Resolved]: {
    color: palette.green10,
    backgroundColor: palette.green50,
    label: 'RESOLVED',
  },
  [PolicyViolationStatusEnumValues.Deleted]: {
    color: palette.gray10,
    backgroundColor: palette.gray45,
    label: 'DELETED',
  },
};

function PolicyViolationLabel({ status }: Props) {
  let classes = useStyle({
    backgroundColor: variants[status].backgroundColor,
    color: variants[status].color,
  });

  return (
    <Label classes={classes.label}>
      <Grid container alignItems={'center'} alignContent={'center'} spacing={1}>
        <Grid item>{variants[status].label}</Grid>
      </Grid>
    </Label>
  );
}

export default memo<Props>(PolicyViolationLabel);
