// @flow
import React, { memo } from 'react';
import { palette } from '@dt/theme';
import { Avatar, Card, CardContent, Grid } from '@material-ui/core';
import type { CloudResourcesList_cloud_resource_list_cloud_resources } from '@dt/graphql-support/types/CloudResourcesList';
import PolicyViolationTag from './PolicyViolationTag';
import TagCloudResourceId from './TagCloudResourceId';
import TagDiscoveredVia from './TagDiscoveredVia';
import TagHostedOn from './TagHostedOn';
import UnifiedAssetTypeIcon from './UnifiedAssetTypeIcon';
import Text from './Text';
import Skeleton from '@material-ui/lab/Skeleton';

type Props = {
  resourceInfo?: CloudResourcesList_cloud_resource_list_cloud_resources,
  isLoading?: boolean,
};

export default memo<Props>(function CloudInventoryCard({
  resourceInfo,
  isLoading,
}: Props) {
  if (isLoading || !resourceInfo) {
    return (
      <Card style={{ minHeight: 290 }}>
        <CardContent>
          <Grid container direction="row" alignContent="flex-start" spacing={2}>
            <Grid item container direction="row" spacing={1}>
              <Grid item>
                <Skeleton
                  animation="wave"
                  variant="circle"
                  width={32}
                  height={32}
                />
              </Grid>
              <Grid item container xs={10} direction="column" spacing={1}>
                <Grid item>
                  <Skeleton animation="wave" width={100} />
                </Grid>
                <Grid item>
                  <Skeleton animation="wave" width={150} />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignContent="flex-start"
              spacing={1}
            >
              <Grid item>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={40}
                  height={24}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={40}
                  height={24}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={40}
                  height={24}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={1} direction="row">
              <Grid item>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={130}
                  height={30}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={180}
                  height={30}
                />
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <Skeleton animation="wave" width={72} height={20} />
              </Grid>
              <Grid item>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={270}
                  height={30}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  const {
    asset_type_name,
    url,
    hosted_on,
    asset_type_icon_url,
    discovered_via,
    aws_arn,
    gcp_project_id,
    mulesoft_domain,
    azure_tenant_id,
    axway_org_id,
    kong_workspace_name,
    open_important_policy_violations_count,
    open_proactive_policy_violations_count,
    open_urgent_policy_violations_count,
  } = resourceInfo;

  const cloud_resource_decorated = {
    aws_arn: resourceInfo.aws_arn ? resourceInfo.aws_arn : null,
    gcp_project_id: resourceInfo.gcp_project_id
      ? resourceInfo.gcp_project_id
      : null,
    azure_tenant_id: resourceInfo.azure_tenant_id
      ? resourceInfo.azure_tenant_id
      : null,
    axway_org_id: resourceInfo.axway_org_id ? resourceInfo.axway_org_id : null,
    mulesoft_domain: resourceInfo.mulesoft_domain
      ? resourceInfo.mulesoft_domain
      : null,
    kong_workspace_name: resourceInfo.kong_workspace_name
      ? resourceInfo.kong_workspace_name
      : null,
  };
  return (
    <Card>
      <CardContent>
        <Grid container direction="row" alignContent="flex-start" spacing={2}>
          <Grid item container direction="row" spacing={1}>
            <Grid item>
              <Avatar
                variant="circle"
                style={{
                  backgroundColor: palette.gray45,
                  width: '32px',
                  height: '32px',
                  padding: '5px',
                }}
              >
                <UnifiedAssetTypeIcon
                  asset_type_icon_url={asset_type_icon_url}
                  asset_type_name={asset_type_name}
                  iconSize={32}
                />
              </Avatar>
            </Grid>
            <Grid item container xs={10} direction="column" spacing={1}>
              <Grid item>
                <Text variant="body" style={{ margin: 0 }}>
                  {asset_type_name}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="caption">
                  {url ? url : 'A URL for this resource was not found'}
                </Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignContent="flex-start"
            spacing={1}
          >
            <Grid item>
              <PolicyViolationTag
                count={open_urgent_policy_violations_count || 0}
                type="urgent"
                margins="0"
              />
            </Grid>
            <Grid item>
              <PolicyViolationTag
                count={open_important_policy_violations_count || 0}
                type="important"
                margins="0"
              />
            </Grid>
            <Grid item>
              <PolicyViolationTag
                count={open_proactive_policy_violations_count || 0}
                type="info"
                margins="0"
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1} direction="row">
            <Grid item>
              {!hosted_on ? (
                <TagHostedOn variant="large" hosted_on={'UNKNOWN'} />
              ) : (
                <TagHostedOn variant="large" hosted_on={hosted_on} />
              )}
            </Grid>
            <Grid item>
              {discovered_via && (
                <TagDiscoveredVia
                  variant="large"
                  discovered_via={discovered_via}
                />
              )}
            </Grid>
          </Grid>
          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <Text variant="caption">
                {aws_arn && 'AWS ARN/ID'}
                {gcp_project_id && 'GCP Project ID'}
                {mulesoft_domain && 'Mulesoft Domain'}
                {azure_tenant_id && 'Azure Tenant ID'}
                {axway_org_id && 'Axway Org Id'}
                {kong_workspace_name && 'Kong Workspace Name'}
              </Text>
            </Grid>
            <Grid item>
              <TagCloudResourceId
                cloud_resource_decorated={cloud_resource_decorated}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
