//@flow
import React, { memo, useState, useRef, type Node } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { palette } from '@dt/theme';
import Popover from '@material-ui/core/Popover';
import { VictoryPie, Slice } from 'victory';

import HostPieChartTooltip from './HostPieChartTooltip';

type Props = {|
  label: string,
  text?: string,
  logo?: ?string,
  enabled: boolean,
  isLoading: boolean,
  children: Node,
  resolvedCount: number,
  proactiveCount: number,
  importantCount: number,
  urgentCount: number,
  showClickLabel: boolean,
|};

const HoverType = {
  URGENT: 'URGENT',
  IMPORTANT: 'IMPORTANT',
  PROACTIVE: 'PROACTIVE',
  RESOLVED: 'RESOLVED',
  LOGO: 'LOGO',
};
type HoverTypeEnum = $Keys<typeof HoverType>;

// The Size here doesn't really matter much. It's only relevant as all
// dimensions specified inside the svg will be relative to this number. For
// example, if the Size is "100", and you want a box with a width of "10" it
// will be one tenth the size of the svg element.
// To put it another way, when this code was written, all units were based off
// the Size value, so changing it actually had no effect!
const Size = 100;

// This determines the width of the ring.
const RingWidth = 8; //<-- you probably want to change this
const InnerRadius = Size / 2 - RingWidth - RingWidth / 2;

export default memo<Props>(function HostPieChart({
  label,
  text,
  logo,
  enabled,
  isLoading,
  children,
  resolvedCount,
  proactiveCount,
  importantCount,
  urgentCount,
  showClickLabel,
}) {
  const hoverTimeoutRef = useRef<null | TimeoutID>(null);
  const hoveringOverPopover = useRef<boolean>(false);
  const [
    hoveredHoverType,
    setHoveredHoverType,
  ] = useState<null | HoverTypeEnum>(null);

  const handleOnMouseOver = (hoverType: $Keys<typeof HoverType>, e) => {
    e.preventDefault();

    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    setAnchor(e.currentTarget);
    setHoveredHoverType(hoverType);
  };

  const handleOnMouseOut = (hoverType, e) => {
    e.preventDefault();

    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    const handleClosePopover = () => {
      // Keep trying to close popover.
      if (hoveringOverPopover.current) {
        hoverTimeoutRef.current = setTimeout(handleClosePopover, 700);
        return;
      }
      setAnchor(null);
      setHoveredHoverType(null);
    };
    hoverTimeoutRef.current = setTimeout(handleClosePopover, 700);
  };

  let [anchor, setAnchor] = useState(null);

  return (
    <>
      <Popover
        id="popoverme"
        style={{ pointerEvents: 'none' }}
        open={hoveredHoverType !== null && anchor !== null}
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{ elevation: 0 }}
        onClose={() => setAnchor(null)}
        transitionDuration={500}
        disableRestoreFocus
      >
        <div
          style={{ pointerEvents: 'all' }}
          onMouseOver={() => {
            hoveringOverPopover.current = true;
          }}
          onMouseOut={() => {
            hoveringOverPopover.current = false;
          }}
        >
          <HostPieChartTooltip
            urgent={
              hoveredHoverType === HoverType.URGENT ||
              hoveredHoverType === HoverType.LOGO
                ? urgentCount
                : null
            }
            important={
              hoveredHoverType === HoverType.IMPORTANT ||
              hoveredHoverType === HoverType.LOGO
                ? importantCount
                : null
            }
            proactive={
              hoveredHoverType === HoverType.PROACTIVE ||
              hoveredHoverType === HoverType.LOGO
                ? proactiveCount
                : null
            }
            resolved={
              hoveredHoverType === HoverType.RESOLVED ||
              hoveredHoverType === HoverType.LOGO
                ? resolvedCount
                : null
            }
          >
            {showClickLabel && (
              <Typography variant="body2">Click For Details</Typography>
            )}
          </HostPieChartTooltip>
        </div>
      </Popover>
      <div style={{ position: 'relative' }}>
        {isLoading && (
          <CircularProgress
            style={{
              zIndex: 1,
              position: 'absolute',
              left: 0,
              top: 0,
              // pointerEvents makes this element ignore mouse events for the
              // purposes of stuff like mouseover. Because we are adding events
              // to the VictoryPie chart, and this Progress indicator is
              // rendered above it, we need this to make sure mouseover events
              // are sent to the chart, not this element.
              pointerEvents: 'none',
            }}
            thickness={1}
            size={150}
          />
        )}
        <svg
          viewBox={`0 0 ${Size} ${Size}`}
          width="100%"
          style={{ maxHeight: 'inherit' }}
        >
          <circle
            cx={Size / 2}
            cy={Size / 2}
            r={InnerRadius}
            fill={
              hoveredHoverType === HoverType.LOGO
                ? palette.gray40
                : palette.gray45
            }
            onMouseOver={e => handleOnMouseOver(HoverType.LOGO, e)}
            onMouseOut={e => handleOnMouseOut(HoverType.LOGO, e)}
          />
          <VictoryPie
            animate={{ duration: 2000, easing: 'elastic' }}
            standalone={false}
            labels={() => null}
            width={Size}
            height={Size}
            data={[
              {
                x: 1,
                y: resolvedCount,
                fill: palette.green,
                hoveredStrokeColor: palette.green10,
                hoverType: HoverType.RESOLVED,
              },
              {
                x: 2,
                y: proactiveCount,
                fill: palette.blue,
                hoveredStrokeColor: palette.blue10,
                hoverType: HoverType.PROACTIVE,
              },
              {
                x: 3,
                y: importantCount,
                fill: palette.yellow,
                hoveredStrokeColor: palette.yellow10,
                hoverType: HoverType.IMPORTANT,
              },
              {
                x: 4,
                y: urgentCount,
                fill: palette.red,
                hoveredStrokeColor: palette.red10,
                radius: Size / 2,
                innerRadius: Size / 2 - RingWidth * 2,
                hoverType: HoverType.URGENT,
              },
            ]}
            dataComponent={
              <Slice
                radius={({ datum: { radius } }) =>
                  radius ? radius : Size / 2 - RingWidth / 2
                }
                innerRadius={({ datum: { innerRadius } }) =>
                  innerRadius ? innerRadius : InnerRadius
                }
              />
            }
            style={{
              data: {
                fill: ({ datum }) => datum.fill,
              },
            }}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onMouseOver: (e, { datum }) => {
                    handleOnMouseOver(datum.hoverType, e);

                    return [
                      {
                        target: 'data',
                        mutation: ({ style, datum }) => ({
                          style: {
                            ...style,
                            stroke: datum.hoveredStrokeColor,
                            strokeWidth: 1,
                          },
                        }),
                      },
                    ];
                  },
                  onMouseOut: (e, { datum }) => {
                    handleOnMouseOut(datum.hoverType, e);

                    return [
                      {
                        target: 'data',
                        mutation: ({ style }) => ({
                          style: {
                            ...style,
                            strokeWidth: 0,
                          },
                        }),
                      },
                    ];
                  },
                },
              },
            ]}
          />
          <filter id="grayscale">
            <feColorMatrix
              type="matrix"
              values="0.3333 0.3333 0.3333 0   0
                    0.3333 0.3333 0.3333 0   0
                    0.3333 0.3333 0.3333 0   0
                    0      0      0      0.5 0"
            />
          </filter>
          {logo && (
            <image
              x={Size / 2 - (Size * 0.5) / 2}
              y={Size / 2 - (Size * 0.5) / 2}
              width={Size * 0.5}
              height={Size * 0.5}
              xlinkHref={logo}
              alt={`${label} Logo`}
              filter={!enabled ? 'url(#grayscale)' : null}
              style={{ pointerEvents: 'none' }}
            />
          )}
          {text && (
            <text
              style={{ pointerEvents: 'none' }}
              x={Size / 2}
              y={Size / 2}
              fill={palette.gray20}
              textAnchor="middle"
              dominantBaseline="central"
            >
              {text}
            </text>
          )}
        </svg>
        {children}
      </div>
    </>
  );
});
