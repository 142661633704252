//@flow
import React, { useEffect } from 'react';
import { CenteredCircularProgress } from '@dt/material-components';
import { useSession } from '../session';
import { NotFoundPage } from '@dt/components';
import { useDispatch, useSelector } from 'react-redux';
import { setTemporaryToken } from '@dt/horizon-api';
import { PublicAppContext } from './shared_links/public_app/usePublicApp';
import type { State as ReduxState } from './store_state_type';

/*
 * @deprecated - Only intended to be used for legacy api containers.
 */
const ApiAccessControls = function ApiAccessControls({
  children,
}: {
  children: any,
}) {
  const { user_account } = useSession({ requireUserSession: false });

  if (!user_account) {
    return <CenteredCircularProgress />;
  }

  if (!user_account.currentUser.can_access_api_inspect) {
    window.location.replace('/dashboard');
    return null;
  }

  return children;
};

/*
 * High order component to augment pages with "api" access controls.
 *
 * @deprecated - Only intended to be used for legacy api containers.
 */
export const accessControlsApi = (Container: any) => {
  return (props: any) => (
    <ApiAccessControls>
      <Container {...props} />
    </ApiAccessControls>
  );
};

/*
 * @deprecated - Only intended to be used for legacy share containers.
 */
const SharedAppAccessControls = function SharedAccessControls({
  token,
  children,
}: {
  token: string,
  children: any,
}) {
  const dispatch = useDispatch();
  const isReady = useSelector((state: ReduxState) => state.ready);

  useEffect(() => {
    if (token) {
      setTemporaryToken(token);
    }
  }, [dispatch, token]);

  if (!token) return <NotFoundPage />;
  if (!isReady) return <CenteredCircularProgress />;

  return (
    <PublicAppContext.Provider value={true}>
      {children}
    </PublicAppContext.Provider>
  );
};

/*
 * High order component to augment pages with "share" access controls.
 *
 * @deprecated - Only intended to be used for legacy share containers.
 */
export const accessControlsShare = (Container: any) => {
  return (props: any) => (
    <SharedAppAccessControls {...props}>
      <Container {...props} />
    </SharedAppAccessControls>
  );
};

/*
 * @deprecated - Only intended to be used for legacy landing page.
 */
const LandingPageAppAccessControls = function SharedAccessControls({
  children,
}: {
  token: string,
  children: any,
}) {
  const { user_account } = useSession({ requireUserSession: false });
  if (!user_account) {
    return <CenteredCircularProgress />;
  }

  return children;
};

/*
 * High order component to augment pages with "landing page" access controls.
 *
 * @deprecated - Only intended to be used for legacy landing page.
 */
export const accessControlsLandingPage = (Container: any) => {
  return (props: any) => (
    <LandingPageAppAccessControls {...props}>
      <Container {...props} />
    </LandingPageAppAccessControls>
  );
};
