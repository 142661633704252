// @flow
import {
  PolicyComplianceStandards,
  type PolicyComplianceStandardType,
  PolicyComplianceStandardCriteria,
  type PolicyComplianceStandardCriteriaType,
  type PolicyRuleType,
  type PolicyRuleTypeList,
} from '@dt/horizon-api';

import {
  type ComplianceRuleTypeDecorated,
  type ComplianceRuleGroupDescription,
  type ComplianceRuleGroupDecorated,
} from './types';

// Icons
import OWASP_ICON_FLAT from './icons/OWASP_ICON_FLAT.svg';
import PCI_ICON from './icons/PCI_ICON.svg';
import NIST_800_53 from './icons/NIST_800_53.svg';

const complianceStandardToIconLegend: {
  +[PolicyComplianceStandardType]: string,
  ...,
} = {
  [PolicyComplianceStandards.OWASP]: OWASP_ICON_FLAT,
  [PolicyComplianceStandards.PCI_DSS]: PCI_ICON,
  [PolicyComplianceStandards.NIST_800_53]: NIST_800_53,
};

export const getIconFromComplianceStandard = (
  standard: PolicyComplianceStandardType,
) => {
  const icon = complianceStandardToIconLegend[standard];
  if (icon) return icon;
  else {
    throw new Error(
      `Couldn't get icon for Compliance Standard ${standard}, complianceStandardToIconLegend is likely out of date`,
    );
  }
};

const complianceStandardToDisplayTextLegend: {
  +[PolicyComplianceStandardType]: string,
  ...,
} = {
  [PolicyComplianceStandards.OWASP]: 'OWASP',
  [PolicyComplianceStandards.PCI_DSS]: 'PCI DSS',
  [PolicyComplianceStandards.NIST_800_53]: 'NIST 800-53',
};

export const getDisplayTextFromComplianceStandard = (
  standard: PolicyComplianceStandardType,
) => {
  const text = complianceStandardToDisplayTextLegend[standard];
  if (text) return text;
  else {
    throw new Error(
      `Couldn't get Display Text for Compliance Standard ${standard}, PolicyComplianceStandardsType is likely out of date`,
    );
  }
};

const complianceStandardCriteriaToDisplayTextLegend: {
  +[PolicyComplianceStandardCriteriaType]: string,
  ...,
} = {
  [PolicyComplianceStandardCriteria.OWASP_A1_2019]: 'A1:2019',
  [PolicyComplianceStandardCriteria.OWASP_A2_2019]: 'A2:2019',
  [PolicyComplianceStandardCriteria.OWASP_A3_2019]: 'A3:2019',
  [PolicyComplianceStandardCriteria.OWASP_A4_2019]: 'A4:2019',
  [PolicyComplianceStandardCriteria.OWASP_A5_2019]: 'A5:2019',
  [PolicyComplianceStandardCriteria.OWASP_A6_2019]: 'A6:2019',
  [PolicyComplianceStandardCriteria.OWASP_A7_2019]: 'A7:2019',
  [PolicyComplianceStandardCriteria.OWASP_A8_2019]: 'A8:2019',
  [PolicyComplianceStandardCriteria.OWASP_A9_2019]: 'A9:2019',
  [PolicyComplianceStandardCriteria.OWASP_A10_2019]: 'A10:2019',
  [PolicyComplianceStandardCriteria.OWASP_W1_2017]: 'WEB1:2017',
  [PolicyComplianceStandardCriteria.OWASP_W2_2017]: 'WEB2:2017',
  [PolicyComplianceStandardCriteria.OWASP_W3_2017]: 'WEB3:2017',
  [PolicyComplianceStandardCriteria.OWASP_W4_2017]: 'WEB4:2017',
  [PolicyComplianceStandardCriteria.OWASP_W5_2017]: 'WEB5:2017',
  [PolicyComplianceStandardCriteria.OWASP_W6_2017]: 'WEB6:2017',
  [PolicyComplianceStandardCriteria.OWASP_W7_2017]: 'WEB7:2017',
  [PolicyComplianceStandardCriteria.OWASP_W8_2017]: 'WEB8:2017',
  [PolicyComplianceStandardCriteria.OWASP_W9_2017]: 'WEB9:2017',
  [PolicyComplianceStandardCriteria.OWASP_W10_2017]: 'WEB10:2017',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_aA_2_1]: 'A2.1',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a1_2_1]: '1.2.1',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a1_3]: '1.3',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a2_3]: '2.3',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a2_2_3]: '2.2.3',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a3_4]: '3.4',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a4_1]: '4.1',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a6_1]: '6.1',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a6_5_10]: '6.5.10',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a6_6]: '6.6',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a8_7]: '8.7',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a10_1]: '10.1',
  [PolicyComplianceStandardCriteria.NIST_800_53_AC_1]: 'AC-1',
  [PolicyComplianceStandardCriteria.NIST_800_53_AC_4]: 'AC-4',
  [PolicyComplianceStandardCriteria.NIST_800_53_AU_11]: 'AU-11',
  [PolicyComplianceStandardCriteria.NIST_800_53_SA_2]: 'SA-2',
  [PolicyComplianceStandardCriteria.NIST_800_53_SA_4]: 'SA-4',
  [PolicyComplianceStandardCriteria.NIST_800_53_SC_1]: 'SC-1',
  [PolicyComplianceStandardCriteria.NIST_800_53_SC_7]: 'SC-7',
  [PolicyComplianceStandardCriteria.NIST_800_53_SC_12]: 'SC-12',
  [PolicyComplianceStandardCriteria.NIST_800_53_SC_16]: 'SC-16',
  [PolicyComplianceStandardCriteria.NIST_800_53_SI_1]: 'SI-1',
  [PolicyComplianceStandardCriteria.NIST_800_53_SI_2]: 'SI-2',
  [PolicyComplianceStandardCriteria.NIST_800_53_CM_2]: 'CM-2',
  [PolicyComplianceStandardCriteria.NIST_800_53_CM_4]: 'CM-4',
};

export const getDisplayTextFromComplianceStandardCriteria = (
  criteria: PolicyComplianceStandardCriteriaType,
) => {
  const text = complianceStandardCriteriaToDisplayTextLegend[criteria];

  if (text) return text;
  else {
    throw new Error(
      `Couldn't get Display Text for Compliance Standard Criteria ${criteria}, PolicyComplianceStandardCriteriaType is likely out of date`,
    );
  }
};
const decorateComplianceRuleType = (
  complianceRuleType: $Diff<PolicyRuleType, { group: mixed, ... }>,
  policyRuleTypes: PolicyRuleTypeList,
): ComplianceRuleTypeDecorated => {
  const { compliance_policy_references } = complianceRuleType;

  // Length of compliance_policy_references must be 1! This is verified by the unit test that these compliance rules come from
  const ruleTypeComplianceReferenceCriteria =
    compliance_policy_references[0].compliance_standard_criteria;

  const related_rule_types = policyRuleTypes.filter(rt =>
    rt.compliance_policy_references.find(
      ref =>
        ref.compliance_standard_criteria ===
        ruleTypeComplianceReferenceCriteria,
    ),
  );

  return {
    ...complianceRuleType,
    related_rule_types: related_rule_types || [],
  };
};

export const decorateComplianceRuleGroup = (
  complianceRuleGroup: ComplianceRuleGroupDescription,
  policyRuleTypes: PolicyRuleTypeList,
): ComplianceRuleGroupDecorated => {
  const { compliance_rule_types } = complianceRuleGroup;

  const compliance_rule_types_decorated = compliance_rule_types.map(crt =>
    decorateComplianceRuleType(crt, policyRuleTypes),
  );

  return {
    ...complianceRuleGroup,
    compliance_rule_types_decorated: compliance_rule_types_decorated || [],
  };
};
