// @flow
import React from 'react';
import {
  PolicyRuleTypeRelevanceValues,
  type PolicyRuleTypeRelevance,
} from '@dt/graphql-support/types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import WarningIcon from '@material-ui/icons/Warning';
import ImportantWarningIcon from '@material-ui/icons/Flag';
import InformationalProactiveIcon from '@material-ui/icons/Info';
import { palette } from '@dt/theme';
import Label from './Label';

type Props = {|
  relevance: PolicyRuleTypeRelevance,
  showLabel?: boolean,
  size?: 'medium' | 'small',
|};

const variants = {
  [PolicyRuleTypeRelevanceValues.Urgent]: {
    backgroundColor: palette.red50,
    color: palette.red30,
    icon: WarningIcon,
  },
  [PolicyRuleTypeRelevanceValues.Important]: {
    backgroundColor: palette.yellow50,
    color: palette.yellow10,
    icon: ImportantWarningIcon,
  },
  [PolicyRuleTypeRelevanceValues.Proactive]: {
    backgroundColor: palette.blue50,
    color: palette.blue30,
    icon: InformationalProactiveIcon,
  },
};

let useStyle = makeStyles({
  relevanceTag: props => ({
    backgroundColor: variants[props.relevance].backgroundColor,
    color: variants[props.relevance].color,
    fontSize: props.size === 'small' ? 10 : 13,
    padding: props.size === 'small' ? 4 : '4px 8px',
  }),
  icon: props => ({
    backgroundColor: variants[props.relevance].backgroundColor,
    color: variants[props.relevance].color,
    fontSize: props.size === 'small' ? 12 : 24,
  }),
});

const RelevanceTag = ({
  relevance,
  showLabel = true,
  size = 'medium',
}: Props) => {
  let classes = useStyle({ relevance, size });
  const Icon = variants[relevance].icon;

  return (
    <Label classes={classes.relevanceTag}>
      <Grid
        container
        alignItems={'center'}
        alignContent={'center'}
        spacing={1}
        wrap="nowrap"
      >
        <Grid item>
          <Grid container>
            <Icon classes={{ root: classes.icon }} />
          </Grid>
        </Grid>
        {showLabel ? <Grid item>{relevance}</Grid> : null}
      </Grid>
    </Label>
  );
};

export default React.memo<Props>(RelevanceTag);
