// @flow
import { createAction } from 'redux-actions';

import { type UserSession } from '@dt/horizon-api';

export const sessionCreate = createAction<'session/create', [?string], ?string>(
  'session/create',
  session_token => session_token,
);

export const sessionCreateSuccess = createAction<
  'session/create/success',
  [UserSession],
  UserSession,
>('session/create/success', user_session => user_session);

export const sessionCreateError = createAction<
  'session/create/error',
  [string],
  string,
>('session/create/error', (errorMessage: string) => errorMessage);
