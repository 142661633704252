// @flow
import { handleActions, type ActionType } from 'redux-actions';
import {
  alertsIntegrationsReceived,
  alertsIntegrationsCreate,
  alertsIntegrationsCreateSuccess,
  alertsIntegrationsCreateError,
  alertsIntegrationsEdit,
  alertsIntegrationsEditSuccess,
  alertsIntegrationsEditError,
  alertsIntegrationEditCancel,
  alertsIntegrationsDelete,
  alertsIntegrationsDeleteSuccess,
  alertsIntegrationsDeleteError,
} from './actions';
import type { AlertsIntegrations } from '@dt/horizon-api';
import immer from 'immer';

export type AlertsIntegrationStateItem = {
  id: string,
  payload: AlertsIntegrations,
  // If there is an update call ongoing to update this item
  isUpdating: boolean,
  // If there is a creation call ongoing to create this item.
  // Since we don't have an id yet, we can only have one item set as `isCreating` on this list
  // with a fixed id of `optimisticUIID`. We remove this item whenever create success action is dispatched
  isCreating: boolean,
  isDeleting: boolean,
  // If any of the calls resulted in error
  error: null | string,
  ...
};

export const optimisticUIID = '0XX0';

export type AlertsIntegrationsState = Map<string, AlertsIntegrationStateItem>;

const initialState = () => new Map([]);

const initialItem = (
  integration: AlertsIntegrations,
  opts?: {
    isCreating?: boolean,
    isUpdating?: boolean,
    error?: string,
    isDeleting?: boolean,
    ...
  },
) => ({
  id: integration.id,
  payload: integration,
  isUpdating: (opts && opts.isUpdating) || false,
  isCreating: (opts && opts.isCreating) || false,
  isDeleting: (opts && opts.isDeleting) || false,
  error: (opts && opts.error) || null,
});

export default handleActions<AlertsIntegrationsState, *>(
  {
    [alertsIntegrationsReceived.toString()]: (
      state,
      action: ActionType<typeof alertsIntegrationsReceived>,
    ) =>
      immer(state, draft => {
        action.payload.map(integration => {
          draft.set(integration.id, initialItem(integration));
        });
      }),

    [alertsIntegrationsCreate.toString()]: (
      state,
      action: ActionType<typeof alertsIntegrationsCreate>,
    ) =>
      immer(state, draft => {
        draft.set(
          optimisticUIID,
          initialItem(
            {
              id: optimisticUIID,
              ...action.payload,
              created_by_user_id: '0',
              date_created: new Date().toString(),
            },
            { isCreating: true },
          ),
        );
      }),

    [alertsIntegrationsCreateSuccess.toString()]: state =>
      immer(state, draft => {
        draft.delete(optimisticUIID);
      }),
    [alertsIntegrationsCreateError.toString()]: (
      state,
      action: ActionType<typeof alertsIntegrationsCreateError>,
    ) =>
      immer(state, draft => {
        const previousAttemptDoc = draft.get(optimisticUIID);
        if (!previousAttemptDoc) {
          // What gave error then?
          return;
        }

        draft.set(
          optimisticUIID,
          initialItem(previousAttemptDoc.payload, { error: action.payload }),
        );
      }),

    [alertsIntegrationsEdit.toString()]: (
      state,
      action: ActionType<typeof alertsIntegrationsEdit>,
    ) =>
      immer(state, draft => {
        const [id, payload] = action.payload;
        const previously = draft.get(id);
        if (!previously) {
          // Editing something that isn't there?
          return;
        }

        draft.set(
          id,
          initialItem(
            { ...previously.payload, ...payload },
            { isUpdating: true },
          ),
        );
      }),

    // Nothing really to do here... alertsIntegrationsReceived should replace our optimistic ui
    [alertsIntegrationsEditSuccess.toString()]: state => state,
    [alertsIntegrationsEditError.toString()]: (
      state,
      action: ActionType<typeof alertsIntegrationsEditError>,
    ) =>
      immer(state, draft => {
        const [id, error] = action.payload;
        const previousAttemptDoc = draft.get(id);
        if (!previousAttemptDoc) {
          // What gave error then?
          return;
        }

        draft.set(id, initialItem(previousAttemptDoc.payload, { error }));
      }),
    [alertsIntegrationEditCancel.toString()]: state =>
      immer(state, draft => {
        draft.delete(optimisticUIID);
      }),
    [alertsIntegrationsDelete.toString()]: (
      state,
      action: ActionType<typeof alertsIntegrationsDelete>,
    ) =>
      immer(state, draft => {
        const id = action.payload;
        const payloadToDelete = draft.get(id);
        if (!payloadToDelete) {
          // What?
          return;
        }

        draft.set(
          id,
          initialItem(payloadToDelete.payload, { isDeleting: true }),
        );
      }),
    [alertsIntegrationsDeleteError.toString()]: (
      state,
      action: ActionType<typeof alertsIntegrationsDeleteError>,
    ) =>
      immer(state, draft => {
        const [id, error] = action.payload;
        const previousAttemptDoc = draft.get(id);
        if (!previousAttemptDoc) {
          // What gave error then?
          return;
        }

        draft.set(id, initialItem(previousAttemptDoc.payload, { error }));
      }),
    [alertsIntegrationsDeleteSuccess.toString()]: (
      state,
      action: ActionType<typeof alertsIntegrationsDeleteSuccess>,
    ) =>
      immer(state, draft => {
        const id = action.payload;
        draft.delete(id);
      }),
    [alertsIntegrationEditCancel.toString()]: state =>
      immer(state, draft => {
        draft.delete(optimisticUIID);
      }),
  },
  initialState(),
);
