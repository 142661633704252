// @flow
import { type Saga } from 'redux-saga';
import { type ActionType } from 'redux-actions';
import { all, call, takeEvery, put } from 'redux-saga/effects';

import { getAlertsIntegrationsList } from './resource_fetch.sagas';
import {
  takePatternAndFetch,
  watchForLoadMoreAndFetchNextPage,
  paginateToEnd,
} from '../resource_fetch/sagas';
import {
  alertsIntegrationsMounted,
  alertsIntegrationsCreate,
  alertsIntegrationsReceived,
  alertsIntegrationsCreateSuccess,
  alertsIntegrationsCreateError,
  alertsIntegrationsEdit,
  alertsIntegrationsEditSuccess,
  alertsIntegrationsEditError,
  alertsIntegrationsDelete,
  alertsIntegrationsDeleteError,
  alertsIntegrationsDeleteSuccess,
} from './actions';
import resourceType from './resourceType';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { alerts_integrations } from '@dt/horizon-api';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    takeEvery(
      alertsIntegrationsCreate.toString(),
      alertsIntegrationsCreateSaga,
    ),
    takeEvery(alertsIntegrationsEdit.toString(), alertsIntegrationEditSaga),
    takeEvery(alertsIntegrationsDelete.toString(), alertsIntegrationDeleteSaga),
    call(
      takePatternAndFetch,
      alertsIntegrationsMounted.toString(),
      function*(): Saga<void> {
        yield call(getAllAlertsIntegrations);
      },
    ),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function*({
      cursor,
    }): Saga<void> {
      yield call(getAlertsIntegrationsList, { cursor });
    }),
  ]);
}

export function* getAllAlertsIntegrations(): Saga<void> {
  yield call(paginateToEnd, getAlertsIntegrationsList, resourceType, {});
}

function* alertsIntegrationsCreateSaga(
  action: ActionType<typeof alertsIntegrationsCreate>,
): Saga<void> {
  const result = yield* callPromise(alerts_integrations.create, action.payload);

  if (result._type === 'response' && result.body.alerts_integrations) {
    yield put(alertsIntegrationsReceived(result.body.alerts_integrations));
    yield put(alertsIntegrationsCreateSuccess());
  } else {
    yield put(alertsIntegrationsCreateError('Could not add integration'));
  }
}

function* alertsIntegrationEditSaga(
  action: ActionType<typeof alertsIntegrationsEdit>,
): Saga<void> {
  const result = yield* callPromise(
    alerts_integrations.patch,
    ...action.payload,
  );

  if (result._type === 'response' && result.body.alerts_integrations) {
    yield put(alertsIntegrationsReceived(result.body.alerts_integrations));
    yield put(alertsIntegrationsEditSuccess(action.payload[0]));
  } else {
    yield put(
      alertsIntegrationsEditError(
        action.payload[0],
        'Could not edit integration',
      ),
    );
  }
}

function* alertsIntegrationDeleteSaga(
  action: ActionType<typeof alertsIntegrationsDelete>,
): Saga<void> {
  try {
    yield* callPromise(alerts_integrations.remove, action.payload);
    yield put(alertsIntegrationsDeleteSuccess(action.payload));
  } catch (e) {
    yield put(alertsIntegrationsDeleteError(action.payload, e.message));
  }
}
