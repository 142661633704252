// @flow
import React, { PureComponent } from 'react';
import {
  PolicyRuleTypeRelevance,
  type PolicyRuleTypeRelevanceType,
} from '@dt/horizon-api';

import {
  type PolicyViolationStatus,
  policyViolationStatus,
} from '@dt/horizon-api';
import PolicyViolationIcon from './PolicyViolationIcon';

type Props = {
  policy_violations_data: $ReadOnlyArray<{
    status: PolicyViolationStatus,
    relevance: PolicyRuleTypeRelevanceType,
  }>,
  huge?: boolean,
};

export default class WarningIconForTasks extends PureComponent<Props> {
  render() {
    const { policy_violations_data, huge } = this.props;

    const unresolved_tasks = policy_violations_data.filter(
      task => task.status === policyViolationStatus.OPEN,
    );

    const hasUrgent = unresolved_tasks.some(
      ({ relevance }) => relevance === PolicyRuleTypeRelevance.URGENT,
    );
    const hasImportant = unresolved_tasks.some(
      ({ relevance }) => relevance === PolicyRuleTypeRelevance.IMPORTANT,
    );

    const hasProactive = unresolved_tasks.some(
      ({ relevance }) => relevance === PolicyRuleTypeRelevance.PROACTIVE,
    );

    if (!hasUrgent && !hasImportant && !hasProactive) {
      return null;
    }

    return (
      <PolicyViolationIcon
        huge={huge}
        type={hasUrgent ? 'bad' : hasImportant ? 'warning' : 'info'}
      />
    );
  }
}
