// @flow
import '@dt/global';
import React, { memo } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
  default as SchemaRestLink,
  ApolloLinkSchemaRestError,
} from '@dt/apollo-link-schema-rest';
import Raven from 'raven-js';
import { TrackingProvider } from '@dt/analytics';
import { materialTheme } from '@dt/theme';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import fetch, { parse, DEFAULT_ERROR_MESSAGE } from '@dt/fetch';
import config from '@dt/config';
import schema from '@dt/graphql-support/restSchema.graphql';
import getMockResolvers from '@dt/graphql-support/getMockResolvers';
import type { ResolverMap } from 'graphql-tools';
import type { Node } from 'react';
import ApplicationRouting from './ApplicationRouting';
import { render } from 'react-dom';
import { ErrorBoundary } from '@dt/components';
import { initialize } from '@dt/session';
import { getStore } from './redux';
import { getMiddleware, runSagas } from './redux/sagas';
import { restNormalizer } from './rest_normalizer';
import {
  LocationProvider,
  createHistory,
  createMemorySource,
} from '@reach/router';

/*
 * Creates an apollo client with application specific configuration.
 *
 * Sets up the following:
 * - Error handling
 * - Resolver links
 * - Caching with an in memory cache
 * - Query & mutation policies
 *
 * @param graphQLMocks - Mocks apollo client resolvers.
 */
const createApolloClient = (
  cache: InMemoryCache,
  graphQLMocks: ?ResolverMap,
) => {
  return new ApolloClient<*>({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError, response, operation }) => {
        if (graphQLErrors) {
          for (const error of graphQLErrors) {
            // Log all errors.
            console.error(
              `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,
            );

            // Handle apollo link schema rest errors - These are not recorded as network errors.
            if (
              error.originalError &&
              error.originalError instanceof ApolloLinkSchemaRestError
            ) {
              // If any backend errors occur with a 5** status codes report to sentry.
              if (
                error.originalError.statusCode >= 500 &&
                error.originalError.statusCode < 600
              ) {
                Raven.captureException(error, {
                  extra: {
                    msg: `Backend reported ${error.originalError.statusCode} status code when executing operation '${operation.operationName}'.`,
                  },
                });

                // Format 5** response error messages to a friendlier message.
                error.message =
                  'We were unable to complete your request at this time. \nPlease try again or contact us at support@datatheorem.com';
              }
              // If any backend errors occur without a error message report to sentry.
              else if (error.originalError.message === DEFAULT_ERROR_MESSAGE) {
                Raven.captureException(error, {
                  extra: {
                    msg: `Backend did not provide an error message when executing operation '${operation.operationName}'.`,
                  },
                });

                // Format unknown response error messages to a friendlier message.
                error.message =
                  'We were unable to complete your request at this time. \nPlease try again or contact us at support@datatheorem.com';
              }
            }
          }
        }

        if (networkError) {
          console.error(`[Network error]: ${networkError}`);
        }

        response.errors = graphQLErrors;
      }),
      SchemaRestLink({
        endpoints: {
          horizon: `${config.horizonAPI}/public`,
          sevenhell: `${config.sevenhellAPI}/_ah/api/userapi`,
          echoserver: `${config.apiProxy}/apis/portal/echo`,
          googleStorage: `${config.googleStorage}/prod-echo_scan_run_logs/1030002`,
          printing_press: `${config.apiProxy}/apis/portal/printing_press`,
        },
        schema,
        fetch,
        fetchParse: parse,
        restNormalizer,
        mockResolvers: getMockResolvers(graphQLMocks),
      }),
    ]),
    cache,
    connectToDevTools: process.env.NODE_ENV !== 'production',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
        // TODO: Should use 'cache-only' for `nextFetchPolicy` but, I haven't figured out all of its side effects yet.
        //       nextFetchPolicy: 'cache-only',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
        // TODO: Should use 'cache-only' for `nextFetchPolicy` but, I haven't figured out all of its side effects yet.
        //       nextFetchPolicy: 'cache-only',
      },
      mutate: {
        // NOTE: DO NOT change `fetchPolicy` or `errorPolicy` there are some nasty dragons here.
        //       To learn more see: https://datatheorem.atlassian.net/wiki/spaces/IW/pages/1297351093/FrontEnd+GraphQL
        errorPolicy: 'all',
      },
    },
  });
};

type Props = {
  graphQLMocks?: void | ResolverMap,
  initialRoute?: string,
};

/*
 * Root providers for the application.
 *
 * Think of this kindof like an IoC container providing base functionality for the rest of the application.
 * This component is used to bootstrap the application and to bootstrap the testing environment.
 *
 * Sets up the following:
 * - Redux
 * - Apollo client (graphql)
 * - Material UI
 * - Analytics & tracking
 *
 * @param graphQLMocks - Mocks apollo client resolvers.
 * @param initialRoute - Starting navigational context - Used for testing.
 * @param children - Sub compoennts to render.
 */
export const ApplicationProviders = function ApplicationProviders({
  graphQLMocks,
  initialRoute,
  children,
}: {
  ...Props,
  children: Node,
}) {
  // Setup redux store.
  const reduxSaga = getMiddleware();
  const store = getStore([reduxSaga]);
  global.__DT_STORE = store;
  runSagas(reduxSaga);

  // Setup apollo cache.
  const cache = new InMemoryCache({
    PaginationInformation: {
      keyFields: false,
    },
  });
  global.__DT_APOLLO_CACHE = cache;

  // Setup apollo client.
  const client = createApolloClient(cache, graphQLMocks);

  // Setup material UI.
  const muiTheme = createMuiTheme(materialTheme());

  // Setup navigational router.
  const history = createHistory(
    // $FlowFixMe - Window is an available option here.
    !initialRoute ? window : createMemorySource(initialRoute),
  );

  return (
    <LocationProvider history={history}>
      <ReduxProvider store={store}>
        <ApolloProvider client={client}>
          <MuiThemeProvider theme={muiTheme}>
            <TrackingProvider>{children}</TrackingProvider>
          </MuiThemeProvider>
        </ApolloProvider>
      </ReduxProvider>
    </LocationProvider>
  );
};

/*
 * Application entry point.
 *
 * Sets up the following:
 * - Root providers
 * - Routing for pages
 *
 * @param initialRoute - Starting navigational context - Used for testing.
 * @param graphQLMocks - Mocks apollo client resolvers.
 */
export const Application = memo<Props>(function Application({
  initialRoute,
  graphQLMocks,
}: Props) {
  return (
    <ApplicationProviders
      initialRoute={initialRoute}
      graphQLMocks={graphQLMocks}
    >
      <ApplicationRouting />
    </ApplicationProviders>
  );
});

/*
 * Runs the application.
 */
export const main = function main() {
  // Single page application DOM container.
  const domContainer = document.getElementById('react');
  if (!domContainer) {
    // Should never happen.
    alert(`Couldn't start the application. Clear cache and refresh.`);
    console.error(`There is no DOM element with id=react!`);
    return;
  }

  // Request authorization.
  initialize();

  // Render the application to the DOM container.
  render(
    <ErrorBoundary>
      <Application />
    </ErrorBoundary>,
    domContainer,
  );

  // $FlowFixMe -- It's a webpack-specific thing
  if (module.hot) {
    module.hot.addStatusHandler(status => {
      if (status === 'check') location.reload();
    });
  }
};
