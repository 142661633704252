// @flow

import { AssetTypeDict } from './types';

let assetTypeToName = {
  [AssetTypeDict.API_DOMAIN]: 'Web Server',
  [AssetTypeDict.CLOUD_RESOURCE]: 'Cloud Resource',
  [AssetTypeDict.RESTFUL_API]: 'RESTful API',
  [AssetTypeDict.SINGLE_PAGE_WEB_APP]: 'Single Page Web App',
  [AssetTypeDict.GRAPHQL_API]: 'GraphQL API',
};

export function getDisplayNameForAsset(
  asset_type: $Values<typeof AssetTypeDict>,
): string {
  return assetTypeToName[asset_type] || 'Unknown Asset';
}
