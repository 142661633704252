// @flow
import React from 'react';
import {
  PolicyRuleTypeRelevance,
  type PolicyRuleTypeRelevanceType,
  policyViolationStatus,
  type PolicyViolationStatus,
} from '@dt/horizon-api';

import PolicyViolationTag from './PolicyViolationTag';

type Props = {|
  +policy_violations_data: $ReadOnlyArray<{
    +status: PolicyViolationStatus,
    +relevance: PolicyRuleTypeRelevanceType,
    ...
  }>,
|};

const containerStyle = {
  display: 'flex',
  alignItems: 'flex-end',
  width: 'fit-content',
  marginLeft: -8, // counteract padding on first child element
};

const PolicyViolationTagStrip = ({ policy_violations_data }: Props) => {
  if (policy_violations_data.length > 0) {
    const urgentViolations = policy_violations_data.filter(
      pv =>
        pv.status === policyViolationStatus.OPEN &&
        pv.relevance === PolicyRuleTypeRelevance.URGENT,
    );
    const importantViolations = policy_violations_data.filter(
      pv =>
        pv.status === policyViolationStatus.OPEN &&
        pv.relevance === PolicyRuleTypeRelevance.IMPORTANT,
    );
    const proactiveViolations = policy_violations_data.filter(
      pv =>
        pv.status === policyViolationStatus.OPEN &&
        pv.relevance === PolicyRuleTypeRelevance.PROACTIVE,
    );

    return (
      <div style={containerStyle}>
        <PolicyViolationTag count={urgentViolations.length} type="urgent" />
        <PolicyViolationTag
          count={importantViolations.length}
          type="important"
        />
        <PolicyViolationTag count={proactiveViolations.length} type="info" />
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      <PolicyViolationTag count={0} type="urgent" />
      <PolicyViolationTag count={0} type="important" />
      <PolicyViolationTag count={0} type="info" />
    </div>
  );
};

export default React.memo<Props>(PolicyViolationTagStrip);
