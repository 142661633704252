// @flow
import React, { type Node } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

type Props = {
  heading: string,
  children: Node,
};

export default React.memo<Props>(function CardWithHeading({
  heading,
  children,
}: Props) {
  return (
    <>
      <Typography variant="subtitle2" color="textPrimary" gutterBottom>
        {heading}
      </Typography>
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </>
  );
});
